import React, { useState } from 'react'
import { useMutation } from '@apollo/client'
import { Form, Input, Button, Row, Col, Divider } from 'antd'
import { UserOutlined, LockOutlined } from '@ant-design/icons'
import {
  setToken,
  setUserMe,
  checkTokenExpire,
  setOperatorId,
  setUserRole,
  setMenuPermission
} from '../../lib/authToken'
import { gql } from 'apollo-boost'
import Swal from 'sweetalert2'
import Head from 'next/head'
import Popup from './popup'
import jwt from 'jsonwebtoken'

import { LabelMenu } from '../../../public/styles/label'

const SIGN_IN = gql`
  mutation signIn($data: SignInInput) {
    signIn(data: $data) {
      __typename
      ... on SignInOutputSuccess {
        user {
          id
          username
          role {
            name
            roleAuthorize{
              masterMenu{
                url
              }
            }
          }
          # webOperatorId
        }
        token
      }
      ... on SignInOutputError {
        messages
      }
    }
  }
`

type Props = {
  title: string
  typeAuth: string
}
const Login = (props: Props) => {
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const [loading, setLoading] = useState(false)
  const [signIn, {loading: loadingSignIn}] = useMutation(SIGN_IN)

  const handleSubmit = async () => {
    setLoading(true)
    signIn({
      variables: {
        data: {
          username: username.replace(/\s/g, ''),
          password: password.replace(/\s/g, ''),
          typeAuth: props.typeAuth,
        },
      },
    }).then(({ data }) => {
      if (data.signIn.__typename === 'SignInOutputSuccess') {
        setUserMe(data.signIn.user.username)
        setUserRole(data.signIn.user.role.name)
        setOperatorId(Number(data.signIn.user.webOperatorId))

        var getMenu = data?.signIn?.user?.role?.roleAuthorize?.map(a => a["masterMenu"]["url"])
        var getSubMenu = data?.signIn?.user?.role?.roleAuthorize?.flatMap(a => a["masterMenu"]["subRoleUrls"])
        var cleanMenu = getMenu.concat(getSubMenu).filter(function(i){ return i != null; })
        var token = jwt.sign(JSON.stringify(cleanMenu), 'permissionMenu');
        setMenuPermission(token);

        const chkExpire = checkTokenExpire(data.signIn.token)
        if (chkExpire) {
          setToken(data.signIn.token)
          window.localStorage.removeItem('roleAuthorizeLists')
          window.location.reload()
        }
      } else {
        setLoading(false)
        Swal.fire({
          title: 'เตือน!',
          text: data.signIn.messages,
          icon: 'warning',
          confirmButtonText: 'ปิด',
        })
      }
    })
  }
  const style = { background: '#0092ff', padding: '8px 0' }
  return (
    <div
      style={{
        background: '#f5f5f5',
        position: 'absolute',
        top: '0',
        left: '0',
        bottom: '0',
        right: '0',
        padding: '15px',
      }}
    >
      <Head>
        <title>Management</title>
      </Head>

      {/* {props.typeAuth === 'userAgent' && <Popup />} */}

     

      <Row gutter={16} justify="center">
        <Col className="gutter-row" xs={24} md={12} lg={10}>
          <div className='login-logo'>
            <img  src="/images/logo-wz.png" width={150} />
            <div className='logo-label'>Admin Management</div>
          </div>
          <Form
            name="normal_login"
            className="login-form"
            initialValues={{ remember: true }}
            onFinish={handleSubmit}
          >
            <Form.Item
              name="username"
              rules={[{ required: true, message: 'Please input your Username!' }]}
              className="mb-2"
            >
              <Input
                size="large"
                value={username}
                onChange={e => {
                  setUsername(e.target.value)
                }}
                prefix={<UserOutlined className="site-form-item-icon" />}
                placeholder="Username"
                disabled={loadingSignIn}
              />
            </Form.Item>
            <Form.Item
              name="password"
              rules={[{ required: true, message: 'Please input your Password!' }]}
              className="mb-2"
            >
              <Input
                size="large"
                value={password}
                onChange={e => {
                  setPassword(e.target.value)
                }}
                prefix={<LockOutlined className="site-form-item-icon" />}
                type="password"
                placeholder="Password"
                disabled={loadingSignIn}
              />
            </Form.Item>
            <Form.Item>
              <Button
                loading={loading}
                block
                size="large"
                type="primary"
                htmlType="submit"
                className="login-form-button"
                disabled={loadingSignIn}
              >
                เข้าสู่ระบบ
              </Button>
            </Form.Item>
          </Form>
        </Col>
      </Row>
    </div>
  )
}

export default Login
